.projects {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    height: auto;
    clip-path: polygon(0 80px, 100% 0%, 100% 100%, 0 calc(100% - 80px));
    background: $pastel-gradient;        
    // background-image: url('/public/images/dots-and-lines.svg');
    background-attachment: fixed;
    padding-left: $margin;
    padding-right: $margin;
    padding-bottom: calc($margin + 80px);
    padding-top: calc($margin / 2 + 80px);
    // inset shadow

    .projects-header {
        font-size: 5rem;
        margin-top: calc($margin / 2.5);
        margin-bottom: calc($margin / 1.25);
        color: $primary-light;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;

        @media (max-width: 1028px) {
            font-size: 3rem;
        }

        @media (max-width: 768px) {
            font-size: 2rem;
        }

        @media (max-width: 400px) {
            font-size: 1.5rem;
        }
    }
    
    .project-card-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 70px;
        justify-content: center;
    
        .project-card {
            flex: 1;
            display: flex;
            flex-direction: column;
            min-width: 400px;
            width: fit-content;
            color: $primary-light;
            background: #ffffff13;
            overflow: hidden;
            text-shadow: none !important;
            box-shadow: 0 0 10px #00000042;
            border-radius: 10px;
            transition: transform .5s ease, color .5s ease, opacity .5s ease;
    
            .image {
                width: 100%;
                height: 200px;
                background-color: $primary-dark;
                background-size: cover;
                background-position: center;
                overflow: hidden;
                object-fit: cover;
                // Make top of image visible
                object-position: 0 0;
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
    
            .description {
                display: flex;
                flex-direction: column;
                padding: 30px;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                flex: 1;
                gap: 10px;
    
                h1, h2, h3, h4, h5, h6, p {
                    font-family: 'Inter', sans-serif;
                    font-weight: 100;
                    line-height: 1.4em;
                    text-decoration: none;
                    margin: 0;
                }

                .link {
                    font-family: 'Inter', sans-serif;
                    font-weight: 200;
                    transition: color .15s ease;
                }

            }
    
            &:hover {
                transform: scale(1.01);
                font-weight: bold !important;
                
                .link {
                    color: $primary-dark !important;
                }
            }
    
            @media (max-width: 768px) {
                min-width: 250px;
            }
        }
    }
}

