@import 'variables';
@import '../components/components';
@import '../pages/pages';

@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/LeagueSpartan-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    src: url('/public/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    src: url('/public/fonts/Inter-VariableFont_slnt,wght.woff') format('woff');
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: $primary_dark;
    color: $primary-light;
    margin: 0;
    text-shadow: 0 0 5px #00000050;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100vw;
 }

a { 
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
}

p {
    margin-bottom: 0;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    position: fixed;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: transparent;
    // border: 1px solid transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: $primary-light;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: darken($primary-light, 10%);
}

/* Gradients */
.super-blue-gradient-text * {
    /* Define the gradient */
    background: $pastel-gradient;

    /* Clip the background to the text */
    -webkit-background-clip: text;
    background-clip: text;

    /* Make the text transparent to show the background */
    color: transparent;
}

.super-blue-gradient {
    background: $pastel-gradient;
}

/* Effects */
.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

.slide-in {
    // transition: transform .35s ease;
    transform: translateX(0);
}

.slide-out {
    // transition: transform .35s ease;
    transform: translateX(-100vw);
}

// Reusable elements


.opaque-on {
    opacity: 1;
    visibility: visible;
}

.opaque-off {
    opacity: 0;
    visibility: hidden;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
}

.neumorphic {
    background: linear-gradient(145deg, #0001, #fff1);
    box-shadow:  5px 5px 8px rgba(0, 0, 0, 0.134),
                -5px -5px 8px rgba(255, 255, 255, 0.13);
}

.neumorphic-md {
    background: linear-gradient(145deg, #0001, #fff1);
    box-shadow:  10px 10px 20px rgba(0, 0, 0, 0.134),
                -10px -10px 20px rgba(255, 255, 255, 0.13);
}

.neumorphic-lg {
    background: linear-gradient(145deg, #0001, #fff1);
    box-shadow:  17px 17px 34px rgba(0, 0, 0, 0.134),
                -17px -17px 34px rgba(255, 255, 255, 0.13);
}

.border-radius {
    border-radius: 15px;
    padding: 50px;
}

.image-link {
    img {
        width: 50%;
        align-self: center;
        display: flex;
        
        top: 0;
        margin: auto;
        max-width: fit-content;

        height: auto;
        max-height: 600px;
        max-width: 100%;
        object-fit: cover;
        border-radius: 10px;

        transition: transform 0.2s ease;
    }
}

.hidden {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    user-select: none;
}