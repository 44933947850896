.type-writer {
    display: inline;
    min-height: 1.5ch;
    line-height: 1em;
    
    .text-container {
        display: flex;
        flex-direction: column;

        .hidden-text {
            position:fixed;
            width: 80vw;
            line-height: fit-content;
        }
    }

    .cursor {
        position: relative;
        width: 1ch;
        top: -0.02ch;
        left: .15ch;
        font-family: 'Inter', sans-serif;
    }
}