$primary-dark: #252525;
$primary-light: #f5f5f5;
$super-blue: #2c7af0;
$maroon: #af0a3b;
$red: #e9013b;
$light-gray: #9e9e9e;
$dark-gray: #424242;
$gold: #e49c00;
$purple: #571be2;
$pastel-gradient: linear-gradient(to bottom right, lighten($super-blue, 30%) 0%, lighten($purple, 25%) 40%, lighten($red, 15%) 130%);

$margin: 15%;