.home {
    .landing {
        height: 100vh;

        .landing-text {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            height: 100%;
            gap: 20px;
            font-family: 'Montserrat', sans-serif;
            
            .name {
                color: $super-blue;
                font-size: 7rem;
                margin: 1rem;
                text-shadow: none !important;
                height: fit-content;
                // font-weight: 400;
                letter-spacing: 1vw;
                // overflow: visible;

                @media (max-width: 1528px) {
                    font-size: 6rem;
                }
                
                @media (max-width: 1200px) {
                    font-size: 5rem;
                }
                
                @media (max-width: 1000px) {
                    font-size: 4rem;
                }

                @media (max-width: 800px) {
                    font-size: 3rem;
                }

                @media (max-width: 600px) {
                    font-size: 2rem;
                }

            }

            .title {
                font-size: 5rem;
                height: 100px;
                color: $super-blue;
                // font-weight: 400;
                letter-spacing: 4vw;
                left: 2vw;
                position: relative;
                
                @media (max-width: 1200px) {
                    font-size: 4rem;
                }
                
                @media (max-width: 1000px) {
                    font-size: 3rem;
                }

                @media (max-width: 800px) {
                    font-size: 2rem;
                }

                @media (max-width: 600px) {
                    font-size: 1rem;
                }


                .cursor {
                    position: relative;
                    left: -3.5vw;
                }
            }

            .software-engineer {
                font-size: 2rem;
                margin: 1rem;
            }
        }
    }
}

