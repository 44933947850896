.footer {
    display: flex;
    min-height: 200px;
    height: fit-content;
    flex-direction: column;
    // clip-path: polygon(0 0, 100% -80px, 100% 100%, 0 100%);
    background-color: darken($primary-dark, 2%);        
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-family: 'Inter', sans-serif;

    .contact-links {
        width: 60%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 3vw;

        img {
            width: 60px;
            height: auto;
            transition: filter 0.1s ease-in-out;
            &:hover {
                filter: brightness(0.9);
            }
        }
    }

    .footer-text {
        text-align: center;
    }
}