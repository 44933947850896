.about {
    display: flex;
    flex-direction: column;
    height: auto;
    clip-path: polygon(0 80px, 100% 0%, 100% 100%, 0 100%);
    background: $pastel-gradient;        
    // background-image: url('/public/images/dots-and-lines.svg');
    background-attachment: fixed;
    padding-left: calc($margin * 2);
    padding-right: calc($margin * 2);
    padding-bottom: calc($margin / 2 + 80px);
    padding-top: calc($margin / 2 + 80px);

    @media (max-width: 1028px) {
        padding-left: calc($margin);
        padding-right: calc($margin);
    }

    @media (max-width: 768px) {
        padding-left: calc($margin / 2);
        padding-right: calc($margin / 2);
    }

    .about-header {
        font-size: 5rem;
        margin-top: calc($margin / 2.5);
        margin-bottom: calc($margin / 1.25);
        color: $primary-light;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;

        @media (max-width: 1028px) {
            font-size: 3rem;
        }

        @media (max-width: 768px) {
            font-size: 2rem;
        }

        @media (max-width: 400px) {
            font-size: 1.5rem;
        }
    }

    .about-text {
        font-size: 1.5rem;
        font-family: 'Inter', sans-serif;
        width: auto;
        text-shadow: 0 0 3px #000000c2;
        padding: 60px;
        
        .about-name {
            line-height: 1.5em;

            .about-description {
                font-size: 1.25rem;
            }
        }

        .about-images {
            margin: 30px;
            margin-top: 60px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .image {
                width: 100px;
                height: auto;
                text-align: center;
                transition: transform .5s ease, filter .5s ease;
                user-select: none;

                @media (max-width: 1028px) {
                    width: 60px;
                    margin-top: 30px;
                }
                
                @media (max-width: 600px) {
                    width: 50px;
                    margin-top: 30px;
                }

                @media (max-width: 400px) {
                    width: 40px;
                    margin-top: 40px;
                }

                &:hover {
                    transform: scale(1.1);
                    filter: brightness(1.1);
                }
            }

            @media (max-width: 768px) {
                margin: 0;
            }
        }
    }
}