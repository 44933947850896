.project-page__wrapper {
    padding-top: calc($margin / 2);
    padding-left: $margin * 1.5;
    padding-right: $margin * 1.5;
    padding-bottom: calc($margin / 2);
    min-height: 100vh;
    height: fit-content;
    
    @media (max-width: 1000px) {
        padding-left: $margin;
        padding-right: $margin;
    }

    .project-page {
        // margin-top: $margin;

        // .project-page__header {
        //     .type-writer > * {
        //         color: ;
        //     }
        // }
        width: fit-content;
        background-color: #0000;
        padding: 90px;
        // border: 1px solid $primary-light;

        @media (max-wdith: 1500px) {
            padding: 70px
        }

        @media (max-width: 1000px) {
            padding: 60px;
        }

        @media (max-width: 500px) {
            padding: 35px;
        }

        .back-button {
            font-family: 'Inter', sans-serif;
            background-color: #0000;
            color: $primary-light;
            border: none;
            font-size: .7rem;
            text-shadow: none !important;
            transition: transform .1s ease, background-color .15s ease;
            padding: 6px;
            margin-bottom: 20px;
            border-radius: 10px;
            cursor: pointer;
            font-family: "Inter", sans-serif;
        
            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }
            
            &:active {
                transform: scale(.95);
            }
        }

        .project-page__content {
            color: $primary-light;
            font-family: 'Inter', sans-serif;
            display: flex;
            flex-direction: column;

            a {
                color: $primary-light;
                transition: color 0.2s ease;
                text-decoration: underline;

                &:hover {
                    color: $primary-dark !important;
                }
            }

            .project-description {
                padding: 15px;
                border-radius: 10px;
                box-shadow:  0 0 9px #00000025;

                p {
                    margin: 0;
                }
            }
        }
    }
}