.nav {
    position: fixed;
    top: 50px;
    left: 3vw;
    z-index: 1000;

    .nav-hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        border: 1px solid $primary-light;
        border-radius: 100px;
        opacity: 0.7;
        width: 30px;
        height: 30px;
        padding: 10px;
        background-color: #0000;
        transition: opacity .5s ease, 
            box-shadow .5s ease, 
            background-color .5s ease;

        .nav-hamburger-line {
            background-color: $primary-light;
            height: 1px;
            width: 20px;
        }

        &:hover {
            cursor: pointer;
            opacity: 1;
            box-shadow: rgba(239, 174, 253, 0.667) 0px 0px 20px;
        }
    }

    .nav-connection-line {
        position: relative;
        background-color: #0000;
        left: 23px;
        width: 4px;
        padding: 0;
        border-left: 1px solid $primary-light;
        border-right: 1px solid $primary-light;
        transition: padding .5s cubic-bezier(1, 1, 0, 0),
            background-color .5s ease;
    }

    .nav-menu {
        max-height: 0;
        max-width: 50px;
        width: calc(100vw - (2 * 3vw) - 10px); // Account for scrollbar width
        // height: 352px;
        overflow: hidden;
        position: relative;
        border: 1px solid #0000;
        border-radius: 15px;
        box-shadow: none;
        background-color: #0000;
        transition: max-height .5s ease,
            max-width .5s ease,
            border .5s ease,
            box-shadow .5s ease,
            background-color .5s ease;

        .nav-menu-list {
            position: relative;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
            // height: 100%;

            .nav-menu-list-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border: 1px solid #0000;
                border-radius: 10px;
                margin: 7px;
                padding: 20px;
                transition: background .5s ease, 
                    border .5s ease, 
                    background-color .5s ease,
                    box-shadow 5s ease;
                height: auto;
                overflow: hidden;

                .nav-menu-list-item-text {
                    text-decoration: none;
                    font-size: 1.5em;
                    color: #0000;
                    background-color: #0000;
                    transition: box-shadow .5s ease, color 1s ease;
                    text-align: left;
                }

                .nav-menu-list-item-selector {
                    font-size: 1.5em;
                    transition: opacity .2s ease;
                    opacity: 0;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    &.active {
        
        .nav-hamburger {
            opacity: 1;
            box-shadow: rgba(239, 174, 253, 0.667) 0px 0px max(100vw, 100vh) max(100vw, 100vh);
            background-color: $primary-dark;
        }

        .nav-connection-line {
            padding-bottom: 50px;
            background-color: $primary-dark;
        }

        .nav-menu {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-end;
            max-height: 390px;
            max-width: 100vw;
            border: 1px solid $primary-light;
            background-color: $primary-dark;

            .nav-menu-list {

                .nav-menu-list-item {
                    border: 1px solid $primary-light;

                    .nav-menu-list-item-text {
                        color: $primary-light;
                    }

                    .nav-menu-list-item-selector {
                        opacity: 0;
                    }

                    &:hover {
                        .nav-menu-list-item-selector {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}