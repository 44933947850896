.skill {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    height: auto;
    background-attachment: fixed;
    padding-left: calc($margin * 1.5);
    padding-right: calc($margin * 1.5);
    padding-bottom: calc($margin + 80px);
    padding-top: calc($margin / 2);

    .skill-header {
        font-size: 5rem;
        margin-top: calc($margin / 2.5);
        margin-bottom: calc($margin / 4);
        color: $primary-light;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;

        @media (max-width: 1028px) {
            font-size: 3rem;
        }

        @media (max-width: 768px) {
            font-size: 2rem;
        }

        @media (max-width: 400px) {
            font-size: 1.5rem;
        }
    }
    
    .skill-card-list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 50px;
        
        h2 {
            color: $primary-light;
            font-size: 2rem;
            margin-top: 40px;
            margin-bottom: 0;
        }

        .skill-card {
            flex: 1;
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 90px;
            justify-content: center;
            align-items: center;
            color: $primary-light;
            // border-radius: 10px;
            // background: linear-gradient(to bottom right, $super-blue 10%, $purple 100%);
            gap: 30px;
            transition: transform .5s ease;
            font-family: 'Inter', sans-serif;

            @media (max-width: 768px) {
                font-size: .75rem;
                height: 50px;
                
                .image {
                    width: 50px !important;
                    height: 50px !important;
                }

                .skill-section {
                    .level-bar {
                        .level-bar-fill {
                            .level-bar-fill-text {
                                font-size: .75rem !important;
                                padding-left: 15px;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
            
            .image {
                display: flex;
                width: 100px;
                height: auto;
                background-position: center;
                overflow: hidden;
                // border-radius: 0 0 10px 10px;
    
                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    filter: brightness(150%);
                }
            }

            .skill-section {
                display: flex;
                flex-direction: column;
                gap: 20px;
                flex: 1;
                height: inherit;

                .level-bar {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: left;
                    height: inherit;
                    border-radius: 100px;
                    overflow: hidden; 

                    .level-bar-fill {
                        display: flex;
                        height: 100%;
                        width: 0%;
                        background: linear-gradient(to right, lighten($super-blue, 20%) 0, lighten($purple, 25%) 15vw, lighten($maroon, 40%) 40vw, lighten($red, 30%) 60vw);
                        transition: width .6s ease-in-out;
                        border-radius: 0 100px 100px 0;
                        justify-content: left;
                        align-items: center;
                        text-align: left;

                        .level-bar-fill-text {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-left: 30px;
                            padding-right: 30px;
                            font-size: 1.7rem;
                        }
                    }
                }

                .mastery-level {
                    font-size: 1rem;
                    height: 0px;
                    color: lighten($super-blue, 20%);
                }
            }
            
            .description {
                padding: 20px;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-family: 'Montserrat', sans-serif;
                flex: 1;
    
                h1, h2, h3, h4, h5, h6, p {
                    margin: 1em;
                    line-height: 1.4em;
                    text-decoration: none;
                }
            }
    
            &:hover {
                transform: scale(1.01);
            }
    
            @media (max-width: 768px) {
                min-width: 250px;
            }
        }
    }
}

